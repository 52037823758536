<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="名称" span="6">
          <vxe-input v-model="listQuery.param.compName" placeholder="请输入名称" clearable />
        </vxe-form-item>
        <vxe-form-item title="联系人" span="6">
          <vxe-input v-model="listQuery.param.contPerson" placeholder="请输入联系人" clearable />
        </vxe-form-item>
        <vxe-form-item title="联系方式" span="6">
          <vxe-input v-model="listQuery.param.contPhone" placeholder="请输入联系方式" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="compName" slot-scope="{row}">
        <el-link type="primary" :underline="false" @click="handleDetail(row)">
          {{ row.compName }}
        </el-link>
      </template>
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'AuditEnterprisePassButton'" size="small" type="text" @click="handlePass(row.id)">通过</el-button>
        <el-button v-permission="'AuditEnterpriseRejectButton'" size="small" type="text" @click="handleRejectAudit(row.id)">拒绝</el-button>
      </template>
    </CustomList>
    <el-dialog
      title="添加登录账号"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <CustomForm
        ref="CustomForm"
        :form-model="formModel"
        :form-items="formItem"
        :form-rules="formRules"
      />
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlePassAudit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import CustomForm from '@/components/CustomForm/index.vue'
export default {
  name: 'Enterprise',
  components: { CustomForm, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      listQuery: {
        param: {
          hasTenant: true,
          auditStatus: 0
        }
      },
      tableColumns: [
        {
          title: '名称',
          slot: 'compName'
        },
        {
          prop: 'serviceTimes',
          title: '服务次数'
        },
        {
          prop: 'compAddres',
          title: '办公地址'
        },
        {
          prop: 'contPerson',
          title: '联系人'
        },
        {
          prop: 'contPhone',
          title: '联系方式'
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ],
      dialogVisible: false,
      formModel: {
        mainId: '',
        account: '',
        password: '',
        status: 1
      }
    }
  },
  computed: {
    formItem() {
      return {
        'account': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '账号',
          'maxlength': 50
        },
        'password': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '密码',
          'maxlength': 50
        }
      }
    },
    formRules() {
      return {
        account: [
          { required: true, message: '账号不能为空', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['getServeOrgsListServe', 'auditAudit']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.getServeOrgsListServe(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleDetail(row) {
      this.$router.push({
        name: 'ServeOrgsDetail',
        params: {
          type: 'detail',
          id: row.id
        },
        query: {
          item: JSON.stringify(row),
          cmOrEntId: row.id
        }
      })
    },
    handlePass(mainId) {
      this.formModel.mainId = mainId
      this.formModel.account = ''
      this.formModel.password = ''
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.CustomForm.$refs.form.clearValidate()
      })
    },
    handlePassAudit() {
      this.requestApi(this.formModel)
      this.dialogVisible = false
    },
    handleRejectAudit(mainId) {
      this.$confirm('确认拒绝吗？', '', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showInput: true
      }).then(data => {
        let reason = ''
        if (typeof data === 'object' && 'value' in data) {
          reason = data.value
        }
        this.requestApi({ reason, mainId, status: 2 })
      }).catch(() => {})
    },
    requestApi(params) {
      params['type'] = 4
      this.auditAudit(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success('操作成功')
        this.handleSearch(true)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
